<template>
  <div class="work-experience">
    <h2>Work Experience</h2>
    <div>
      <div v-for="work in resumeData.workExperience" :key="work.title" class="work-experience-entry">
        <h3>{{ work.title }}</h3>
        <span style="font-style:italic"><strong><p>{{ work.employer }}</p></strong></span>
        <p style="font-size: 14px;">{{ work.dates }}</p>
        <ul>
          <li v-for="responsibility in work.responsibilities" :key="responsibility">{{ responsibility }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import resumeData from "../resume.json";

export default {
  name: "WorkExperience",
  data() {
    return {
      resumeData: resumeData,
    };
  },
};
</script>

<style>
/* Add any custom styles for the WorkExperience component here */
</style>
