<template>
  <div class="app">
  
    <main>
      <div class="section">
          <PersonalInfo />
        </div>
     <div class="outer-container">
      <div class="container-main" id="left-container">
        <div class="section">
          <PersonalSummary />
        </div>
        <div class="section">
          <WorkExperience />
        </div>
      </div>
      <div class="container-right" id="right-container">
      
        <div id="skills-container" class="section">
          <skillList />
        </div>
        <div class="section">
          <EducationHistory />
        </div>
      </div>
     </div>
    </main>
  </div>
  <div class="made-with-vue">
      <span>Made with </span>
      <img class="vue-logo" :src="vueLogo" alt="Vue logo" />
    </div>


</template>

<script>
import PersonalInfo from "./components/PersonalInfo.vue";
import EducationHistory from "./components/EducationHistory.vue";
import WorkExperience from "./components/WorkExperience.vue";
import skillList from "./components/skillList.vue"
import PersonalSummary from "./components/PersonalSummary.vue";
import vueLogo from './assets/logo.png';

export default {
  name: "App",
  components: {
    PersonalInfo,
    EducationHistory,
    WorkExperience,
    skillList,
    PersonalSummary
  },
  data() {
    return {
      vueLogo: vueLogo
    }
  },
};
</script>

<style>
/* #app {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5f5f5;
  min-height: 100vh;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
} */

#app {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
}

/* .section {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 20px 20px 0;
  flex-basis: calc(50% - 20px);
} */


.outer-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.container-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 75%;
}

.container-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 25%;
}

/* CSS media query for smaller screens */
@media (max-width: 768px) {
  .outer-container {
    flex-direction: column;
  }

  .container-main {
    width: 100%;
  }

  .container-right {
    width: 100%;
  }
}


.top-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: no-wrap;
  width: 100%;
  gap: 20px;
}
.name-container {
  display: flex;
  align-items: center;
}

.profile-picture {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
}


.section {
  background-color: white;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.personal-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}



h1 {
  font-size: 95px;
  font-weight: 700;
  margin: 10px 0;
  background-color: #7474BF;
    background-image: linear-gradient(to bottom, #348AC7, #7474BF);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}

nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

nav a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

nav span {
  font-size: 18px;
  color: #333;
}

nav a:hover {
  color: #666;
}

h4, h5, h6 {
  font-size: 28px;
  font-weight: 700;
  margin: 10px 0;
}

h2 {
 font-size: 28px;
 background-color: #7474BF;
    background-image: linear-gradient(to bottom, #348AC7, #7474BF);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}

h3 {
  font-size: 22px;
}

p, li {
  font-size: 18px;
  line-height: 1.5;
  margin: 5px 0;
}

.work-experience-entry {
  background-color:white;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

html, body {
  margin: 0;
  padding: 0;
}

.made-with-vue {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  font-size: 18px;
}


.vue-logo {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}


</style>
