<template>
    <div class="skill-list">
      <h2>Skills</h2>
      <ul>
        <li v-for="skill in resumeData.skills" :key="skill">
          <strong>{{ skill }}</strong>
          
        </li>
      </ul>
    </div>
  </template>
  
    
    <script>
    import resumeData from "../resume.json";
    
    export default {
      name: "skillList",
      data () {
        return {
          resumeData: resumeData,
        };
      },
    };
    </script>
    
    <style>
    /* Add any custom styles for the skillList component here */
.skill-list ul {
  list-style-type: none;
  padding-left: 0;
}

    </style>
    