<template>
  <div class="educationHistory">
    <h2>Education</h2>
    <p style="font-style:italic">{{ resumeData.education.degree }}</p>
    <strong><p>{{ resumeData.education.school }}</p></strong>
    <p>{{ resumeData.education.dates }}</p>
  </div>
</template>

  
  <script>
  import resumeData from "../resume.json";
  
  export default {
    name: "EducationHistory",
    data () {
      return {
        resumeData: resumeData,
      };
    },
  };
  </script>
  
  <style>
  /* Add any custom styles for the Education component here */
  </style>

