<template>
  <div class="personal-summary">
    <h2>Personal Summary</h2>
        <p> {{ resumeData.personalInfo.summary  }}</p>
  </div>
</template>

<script>
import resumeData from "../resume.json";

export default {
  name: "PersonalSummary",
  data() {
    return {
      resumeData: resumeData,
    };
  }
};
</script>

<style>
/* Add any custom styles for the PersonalInfo component here */
</style>