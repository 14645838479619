<template>
  <div class="personal-info">
    <div class="top-outer-container">
      <div class="image-and-name-container">
        <img class="profile-picture" src="../assets/profPic.jpg" alt="Profile Picture" />
        <div class="name-container">
          <h1>Jacob Jones</h1>
        </div>
      </div>
        <div class="nav-container">
          <nav>
             <a href="mailto:jacobkjones@gmail.com">Email</a>
             <span class="divider">/</span>
             <a href="tel:+17066690432">Phone</a>
             <span class="divider">/</span>
             Cumming, GA
             <span class="divider">/</span>
             <a href="https://jacobkjones.com/" target="_blank">jacobkjones.com</a>
             <span class="divider">/</span>
             <a href="https://www.linkedin.com/in/linkedwithjacob" target="_blank">LinkedIn</a>
             <!-- To Add Later <a href="https://github.com/your-github-profile" target="_blank">GitHub</a> -->
          </nav>
      </div>
    </div>
  </div>
</template>

<script>
import resumeData from "../resume.json";
import profPic from "../assets/profPic.jpg";

export default {
  name: "PersonalInfo",
  data() {
    return {
      resumeData: resumeData,
      profPic: profPic,
    };
  },
};
</script>


<style>
/* Add any custom styles for the PersonalInfo component here */
/* New media query for mobile devices */
@media screen and (max-width: 768px) {
  nav {
    flex-direction: column;
    gap: 10px;
  }
}

.divider {
  margin: 0 10px;
  display: inline;
}

.image-and-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.profile-picture {
  width: 150px;
  height: 150px;
  max-width: 150px;
  object-fit: cover;
  border-radius: 50%;
 /* position: absolute; */
  left: 0;
  margin-right: 20px;
  margin-left: 50px;
  z-index: 1;
}



@media (max-width: 600px) {
  .image-and-name-container {
    display: grid;
  }

  .profile-picture {
    margin: 0 auto 0px auto;
  }

  .name-container {
    text-align: center;
  }
}



/* Hide the divider on mobile devices */
@media (max-width: 600px) {
  .divider {
    display: none;
  }
}



/* Updated styles */
.top-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
}

.name-container {
  text-align: center;
  padding-left: 20px;
}

@media (min-width: 1100px) {
  .profile-picture {
    position: absolute;
    left: 0;
    margin-left: 10px;
  }
}




@media (max-width: 600px) {
  .top-outer-container {
    flex-direction: column;
  }

  .profile-picture {
    position: static;
    margin-bottom: 20px;
    left: 0;
    margin-left: calc(50% - 80px);
  }
}

</style>